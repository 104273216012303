import {DonorLookup} from '@matchsource/api-generated/subject';
import {DonorLookupModel} from '@matchsource/models/donor';
import {Serializer} from '@matchsource/api-utils';
import {SOURCE_PRODUCT_TYPE_LIST_MAP} from '@matchsource/models/source';

export const donorLookupSerializer: Serializer<DonorLookupModel, DonorLookup> = {
  fromDTO(input: DonorLookup): DonorLookupModel {
    return {
      guid: input.guid,
      nmdpId: input.nmdpId,
      grId: input.grId,
      displayGrid: input.displayGrid,
      localId: input.localId,
      bpGuid: input.bpGuid,
      emdisId: input.emdisId,
      status: input.status,
      availabilityDate: input.availabilityDate,
      displayId: input.displayId,
      categoryCode: SOURCE_PRODUCT_TYPE_LIST_MAP[input.categoryCode || ''],
    };
  },
};
