import {DonorLookup} from '@matchsource/api-generated/subject';
import {DonorLookupModel} from '@matchsource/models/donor';
import {createPaginatedataSetFromArray} from '@matchsource/api-utils';
import {donorLookupSerializer} from './donor-lookup.serializer';
import {PaginatedDataSetModel} from '@matchsource/models/core';
import {Serializer} from '@matchsource/api-utils';

export const donorLookupSetSerializer: Serializer<PaginatedDataSetModel<DonorLookupModel>, DonorLookup[]> = {
  fromDTO(input: DonorLookup[]): PaginatedDataSetModel<DonorLookupModel> {
    return createPaginatedataSetFromArray<DonorLookupModel, DonorLookup>(input, donorLookupSerializer);
  },
};
