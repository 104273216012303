import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {DonorBaseModel, DonorLookupModel} from '@matchsource/models/donor';
import {PaginatedDataSetModel} from '@matchsource/models/core';
import {donorBaseSerializer} from './donor-base.serializer';
import {typingResultsSerializer} from '@matchsource/api/hla-history';
import {
  DonorControllerService,
  LookupControllerService,
  DonorEligibility,
  Donor,
} from '@matchsource/api-generated/subject';
import {EthnicityRacesModel} from '@matchsource/models/source';
import {mapEthnicityRaceDtoToModel} from '@matchsource/api/source-base';
import {donorLookupSetSerializer} from './donor-lookup-set.serializer';
import {HttpContext} from '@angular/common/http';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import {uniq} from '@matchsource/utils';
import {skipSpinner, useSpinner} from '@matchsource/core';
import {PreferredTestResultsModel} from '@matchsource/models/hla';

@Injectable({
  providedIn: 'root',
})
export class DonorApiService {
  constructor(
    private readonly donorController: DonorControllerService,
    private readonly lookupControllerService: LookupControllerService
  ) {}

  private static getBasicCustomErrorHandling(): (context?: HttpContext) => HttpContext {
    return setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingDonorData);
  }

  getExtendedDonor(donorId: MsApp.Guid): Observable<Donor & {preferredTestResult: PreferredTestResultsModel}> {
    const context = DonorApiService.getBasicCustomErrorHandling();

    return this.donorController
      .getDonorByGuid(
        {
          guid: donorId,
        },
        {
          context: context(),
        }
      )
      .pipe(
        map(dto => ({
          ...dto,
          preferredTestResult: typingResultsSerializer.fromDTO(dto.ptr),
        }))
      );
  }

  search(searchTerm = ''): Observable<PaginatedDataSetModel<DonorLookupModel>> {
    const context = DonorApiService.getBasicCustomErrorHandling();

    return this.lookupControllerService
      .lookupDonorById({donorId: searchTerm}, {context: context()})
      .pipe(map(input => donorLookupSetSerializer.fromDTO(input)));
  }

  getDonors(donorIds: MsApp.Guid[], {silent = false}: {silent?: boolean} = {}): Observable<DonorBaseModel[]> {
    const context = useSpinner(silent)();

    return this.donorController
      .getDonorsByGuids(
        {
          body: uniq(donorIds),
        },
        {context}
      )
      .pipe(map(donors => donors.map(donor => donorBaseSerializer.fromDTO(donor))));
  }

  getDonorsBase(donorIds: MsApp.Guid[]): Observable<DonorBaseModel[]> {
    return this.donorController
      .getDonorsBaseByGuids({
        body: donorIds,
      })
      .pipe(map(donors => donors.map(donor => donorBaseSerializer.fromDTO(donor))));
  }

  getDonorEthnicityRace(donorId: MsApp.Guid, silent = false): Observable<EthnicityRacesModel> {
    const skipSpinnerContext = silent ? skipSpinner() : null;
    const context = DonorApiService.getBasicCustomErrorHandling();

    return this.donorController
      .getDonorEthnicityRace(
        {
          guid: donorId,
        },
        {
          context: context(skipSpinnerContext),
        }
      )
      .pipe(map(sourceEthnicityRaces => mapEthnicityRaceDtoToModel(sourceEthnicityRaces)));
  }

  getDonorEligibility(donorId: MsApp.Guid, fulfillmentId: MsApp.Guid): Observable<DonorEligibility> {
    const context = DonorApiService.getBasicCustomErrorHandling();

    return this.donorController.getDonorEligibility(
      {
        guid: donorId,
        fulfillmentOrderGuid: fulfillmentId,
      },
      {
        context: context(),
      }
    );
  }
}
